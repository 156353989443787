<template>
  <div class="home">
    <h1 style="text-align:center;">Howdy!</h1>
    <p style="text-align:center;">
      Welcome to my personal portfolio website! Feel free to take a look at my work history,
      the technical details of the website, and whatever else is going on here.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Home'
};
</script>
